@import "variables.scss";
@import "mixin.scss";

html {
  box-sizing: border-box;
  scroll-behavior: smooth;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

body {
  font-family: "Open-Sans", sans-serif;
}


a, img, p, h1, h2, h3, h4, input {
  @include clearDefaultStyles;
  color: $colorBlack;
}

img, picture {
  @include clearDefaultStyles;
  display: block;
  max-width: 100%;
}

.list-reset {
  @include clearDefaultStyles;
}

.btn-reset {
  @include clearDefaultStyles;
  cursor: pointer;
}

.container {
  max-width: 1440px;
  margin: 0 auto;
}

.main {

  &-container {
    display: flex;
  }
}

.header {
  width: calc(50vw - 75px);
  height: 60px;
  display: flex;
  justify-content: space-between;

  &-left {
    font-size: 18px;
    font-weight: 600;
    margin: auto 0 auto 10px;
  }

  &-right {
    display: flex;
    align-items: center;

    &-date {
      margin-left: 10px;
    }
  }

  &-button {
    border-radius: 25px;
    padding: 8px;
    border: 1px solid #000;
    background-color: #FFF;
    color: #000;
    margin-left: 10px;

    &-right {
      margin-right: 10px;
    }
  }
}

.aside {
  padding-top: 60px;
  float: left;
  width: 150px;
  height: calc(100vh - 0px);
  background-color: #FFF;
  color: #000;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  &-list {

    &-item {
      background-color: #FFF;
      color: #000;
    }
  }

  &-link {
    display: block;
    width: 150px;
    padding: 10px 20px;

    &-active {
      background-color: #dadada;
    }
  }
}

.auth {
  background-color: #FFF;
  border-radius: 20px;
  padding: 38px;
  width: 600px;

  &-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100vh;
  }

  &-menu {
    display: flex;
    justify-content: center;
    margin: 0 auto;

    &-button {
      margin: 0 10px;
      padding: 10px;
      border-radius: 15px;
      background-color: #FFF;
      color: #000;
      border: none;
      cursor: pointer;

      &-active {
        background-color: #EDEDED;
      }
    }
  }
}

.login {
  display: flex;
  flex-direction: column;


  &-text {
    margin-top: 10px;
  }

  &-input {
    margin-top: 10px;
  }

  &-bottom {
    margin-top: 100px;
    display: flex;
    justify-content: space-between;
  }

  &-checkbox {

    &-text {
      margin-left: 5px;
    }
  }

  &-button {
    width: 100%;
    padding: 10px 0;
    background-color: #ead029;
    color: #000;
    border: none;
    border-radius: 5px;
    margin-top: 15px;
    cursor: pointer;
  }
}

.probe {

  &-center {

    &-wrapper {
      display: flex;
      flex-direction: column;
    }
  }

  &-select {
    width: 100%;
    display: flex;

    &-button {
      width: 100%;
      border: 1px solid #000;
      padding: 10px;
      background-color: #FFF;
      color: #000;

      &-active {
        background-color: #999;
      }
    }
  }

  &-list-item {
    padding: 5px;
    display: flex;
    justify-content: space-between;
    text-align: center;

    &-header {
      background-color: #dadada;
      color: #999;
    }

    &-1 {
      width: 5%;
    }

    &-2 {
      width: 7%;
    }

    &-3 {
      width: 3%;
    }

    &-4 {
      width: 10%;
    }

    &-5 {
      width: 10%;
    }

    &-6 {
      width: 15%;
    }

    &-7 {
      width: 15%;
    }

    &-8 {
      width: 15%;
    }

    &-9 {
      width: 15%;
    }

    &-10 {
      width: 5%;
    }
  }
}

.profile {

  &-header {
    width: calc(100vw - 75px);
    height: 60px;
    border-bottom: 1px solid #999;
    display: flex;
    align-items: center;
    font-size: 18px;
    font-weight: 600;

    &-item {
      margin-left: 20px;
      cursor: pointer;
    }
  }

  &-center {

    &-wrapper {
      padding: 10px;
      display: flex;
      flex-direction: column;
    }

    &-buttons {
      width: 570px;
      display: flex;
    }

    &-button {
      width: 100%;
      border: 1px solid #000;
      padding: 10px 0;
      background-color: #FFF;
      color: #000;

      &-active {
        background-color: #999;
      }
    }

    &-view {
      width: 570px;
      margin-top: 10px;
      padding: 10px;
      border-radius: 15px;
      border: 1px solid #999;
      padding: 10px;
      display: flex;
      flex-direction: column;

      &-top {
        display: flex;
        justify-content: space-between;
      }

      &-bottom {
        display: flex;
        flex-direction: column;
        margin-top: 10px;
      }

      &-text {
        margin-top: 10px;
        color: #8c8c8c;
      }

      &-input {
        margin-top: 10px;
        padding: 10px;
        background-color: #FFF;
        border: 1px solid #999;
        color: #000;
        border-radius: 20px;
      }

      &-button {
        align-self: center;
        margin-top: 20px;
        width: 290px;
        padding: 10px 0;
        border: none;
        border-radius: 15px;
        background-color: #ffc100;
        color: #FFF;
      }
    }
  }
}

.invalid-input {
  border: 1px solid #ff0000;
}
