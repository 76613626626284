html {
  box-sizing: border-box;
  scroll-behavior: smooth;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

body {
  font-family: "Open-Sans", sans-serif;
}

a, img, p, h1, h2, h3, h4, input {
  margin: 0;
  padding: 0;
  border: none;
  outline: none;
  text-decoration: none;
  list-style: none;
  color: #000;
}

img, picture {
  margin: 0;
  padding: 0;
  border: none;
  outline: none;
  text-decoration: none;
  list-style: none;
  display: block;
  max-width: 100%;
}

.list-reset {
  margin: 0;
  padding: 0;
  border: none;
  outline: none;
  text-decoration: none;
  list-style: none;
}

.btn-reset {
  margin: 0;
  padding: 0;
  border: none;
  outline: none;
  text-decoration: none;
  list-style: none;
  cursor: pointer;
}

.container {
  max-width: 1440px;
  margin: 0 auto;
}

.main-container {
  display: flex;
}

.header {
  width: calc(50vw - 75px);
  height: 60px;
  display: flex;
  justify-content: space-between;
}
.header-left {
  font-size: 18px;
  font-weight: 600;
  margin: auto 0 auto 10px;
}
.header-right {
  display: flex;
  align-items: center;
}
.header-right-date {
  margin-left: 10px;
}
.header-button {
  border-radius: 25px;
  padding: 8px;
  border: 1px solid #000;
  background-color: #FFF;
  color: #000;
  margin-left: 10px;
}
.header-button-right {
  margin-right: 10px;
}

.aside {
  padding-top: 60px;
  float: left;
  width: 150px;
  height: calc(100vh - 0px);
  background-color: #FFF;
  color: #000;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.aside-list-item {
  background-color: #FFF;
  color: #000;
}
.aside-link {
  display: block;
  width: 150px;
  padding: 10px 20px;
}
.aside-link-active {
  background-color: #dadada;
}

.auth {
  background-color: #FFF;
  border-radius: 20px;
  padding: 38px;
  width: 600px;
}
.auth-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
}
.auth-menu {
  display: flex;
  justify-content: center;
  margin: 0 auto;
}
.auth-menu-button {
  margin: 0 10px;
  padding: 10px;
  border-radius: 15px;
  background-color: #FFF;
  color: #000;
  border: none;
  cursor: pointer;
}
.auth-menu-button-active {
  background-color: #EDEDED;
}

.login {
  display: flex;
  flex-direction: column;
}
.login-text {
  margin-top: 10px;
}
.login-input {
  margin-top: 10px;
}
.login-bottom {
  margin-top: 100px;
  display: flex;
  justify-content: space-between;
}
.login-checkbox-text {
  margin-left: 5px;
}
.login-button {
  width: 100%;
  padding: 10px 0;
  background-color: #ead029;
  color: #000;
  border: none;
  border-radius: 5px;
  margin-top: 15px;
  cursor: pointer;
}

.probe-center-wrapper {
  display: flex;
  flex-direction: column;
}
.probe-select {
  width: 100%;
  display: flex;
}
.probe-select-button {
  width: 100%;
  border: 1px solid #000;
  padding: 10px;
  background-color: #FFF;
  color: #000;
}
.probe-select-button-active {
  background-color: #999;
}
.probe-list-item {
  padding: 5px;
  display: flex;
  justify-content: space-between;
  text-align: center;
}
.probe-list-item-header {
  background-color: #dadada;
  color: #999;
}
.probe-list-item-1 {
  width: 5%;
}
.probe-list-item-2 {
  width: 7%;
}
.probe-list-item-3 {
  width: 3%;
}
.probe-list-item-4 {
  width: 10%;
}
.probe-list-item-5 {
  width: 10%;
}
.probe-list-item-6 {
  width: 15%;
}
.probe-list-item-7 {
  width: 15%;
}
.probe-list-item-8 {
  width: 15%;
}
.probe-list-item-9 {
  width: 15%;
}
.probe-list-item-10 {
  width: 5%;
}

.profile-header {
  width: calc(100vw - 75px);
  height: 60px;
  border-bottom: 1px solid #999;
  display: flex;
  align-items: center;
  font-size: 18px;
  font-weight: 600;
}
.profile-header-item {
  margin-left: 20px;
  cursor: pointer;
}
.profile-center-wrapper {
  padding: 10px;
  display: flex;
  flex-direction: column;
}
.profile-center-buttons {
  width: 570px;
  display: flex;
}
.profile-center-button {
  width: 100%;
  border: 1px solid #000;
  padding: 10px 0;
  background-color: #FFF;
  color: #000;
}
.profile-center-button-active {
  background-color: #999;
}
.profile-center-view {
  width: 570px;
  margin-top: 10px;
  padding: 10px;
  border-radius: 15px;
  border: 1px solid #999;
  padding: 10px;
  display: flex;
  flex-direction: column;
}
.profile-center-view-top {
  display: flex;
  justify-content: space-between;
}
.profile-center-view-bottom {
  display: flex;
  flex-direction: column;
  margin-top: 10px;
}
.profile-center-view-text {
  margin-top: 10px;
  color: #8c8c8c;
}
.profile-center-view-input {
  margin-top: 10px;
  padding: 10px;
  background-color: #FFF;
  border: 1px solid #999;
  color: #000;
  border-radius: 20px;
}
.profile-center-view-button {
  align-self: center;
  margin-top: 20px;
  width: 290px;
  padding: 10px 0;
  border: none;
  border-radius: 15px;
  background-color: #ffc100;
  color: #FFF;
}

.invalid-input {
  border: 1px solid #ff0000;
}

